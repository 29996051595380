import tableState from "../../templates/table/state";
import moment from "moment";

export default {
  ...tableState({
    dataType: "conversions",
    from: moment()
      .startOf("month")
      .subtract(16, "months")
      .format("YYYY-MM"),
    to: moment().format("YYYY-MM")
  })
};
